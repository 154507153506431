import React from "react"
import LinkButton from "../../components/utilities/buttons/link-button/linkButton"
import Layout from "../../layout/layout"
import style from "./404.module.scss"

const ErrorPage = () => {
  return (
    <Layout darkHeader className={style.errorPage} title="404">
      <div>
        <h1>404</h1>
        <h2>Sorry, we can’t find that page.</h2>
        <p>
          This page stinks, no one wants to be here, quick get back to the sweet
          smelling site of OCD.
        </p>
        <LinkButton href="/" className={style.button}>
          RETURN TO HOME
        </LinkButton>
      </div>
    </Layout>
  )
}
export default ErrorPage
